/* ContactUs.css */
.contact-us {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-type-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.form-type-buttons button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
}

.form-type-buttons button.active {
  background-color: #007bff;
  color: white;
}

.form-type-buttons button:hover {
  background-color: #0056b3;
  color: white;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.contact-form textarea {
  resize: vertical;
  height: 100px;
}

.contact-form button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #0056b3;
}

 .thank-you-message {
    text-align: center; /* Centers text horizontally */
    margin: 0 auto;     /* Centers container horizontally */
    position: relative;
    top: 50%;
    transform: translateY(-50%); /* Centers container vertically */
    display: flex; /* Optional: if parent has a flex layout */
    justify-content: center; /* Flex-specific centering */
    align-items: center; /* Flex-specific centering */
  }

.twitter-button {
  display: flex;
  justify-content: center; /* Center the button */
  margin: 20px 0;
}

.twitter-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #000000; /* X brand black color */
  color: white;
  text-decoration: none;
  border: 1px solid #000000; /* Border matching the black brand color */
  border-radius: 50px; /* Rounded button for a modern look */
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s ease; /* Smooth transitions */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.twitter-link:hover {
  background-color: white; /* Reverse hover effect */
  color: #000000; /* Text matches the black branding */
  border-color: #000000; /* Keeps the same border */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhance shadow */
}

.twitter-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px; /* Reduce margin for better spacing */
  filter: invert(100%); /* Ensure the icon is white against black */
}

.explanatory-text {
  font-size: 1rem; /* Base size for readability */
  line-height: 1.6; /* Comfortable line spacing */
  color: #333; /* Neutral, user-friendly text color */
  margin: 1rem auto; /* Space around the text */
  max-width: 600px; /* Limit text width for better readability */
  text-align: center; /* Center-align for emphasis */
  padding: 0.5rem; /* Padding for breathing space */
  background-color: #f9f9f9; /* Light background for contrast */
  border: 1px solid #ddd; /* Subtle border to define the section */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */

}